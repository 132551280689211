/* media-query minimum range */
@media (min-width: 577px) {
.d-tab {
    visibility: visible;
}
.d-accordion {
	visibility: hidden;
}
}
@media (min-width: 768px) {


.popup-backbutton i {
    display: none;
}
}

@media (min-width: 991px) {
.multi-images {
    min-height: 230px;
}
.compare-prd > a > img {
    width: 237px;
}
}

@media (min-width: 1080px) {
.insta-images .col-md-6:first-child.col-12 {
	overflow: hidden;
	height: 554px;
}
}

@media (min-width: 1200px) {
.main {
    margin: 40px 0 67px 0;
}
.d-mt0 {
    margin-top: 0;
}
.multi-images {
    min-height: 270px;
}
.categories-header {
    margin-right: 20px;
}
.categories-list.categories img {
    width: 130px;
    display: table-cell;
}
.categories-list.categories .figcaption {
    width: auto;
	display: table-cell;
}
.divider.m-t {
    margin-top: 80px;
}
.carousel-container {
	max-width: 92.5vw;
	margin: auto;
}
.carousel-container .owl-prev,
.carousel-container .owl-next {
	position: absolute;
	top: 6vw;
	color: #de1f65;
	cursor: pointer;
	font-size: 20px;
	background: transparent;
    border: none;
}
.carousel-container .owl-prev {
	left: -35px;
}
.carousel-container .owl-next {
	right: -35px;
}
.categories-carousel .owl-item {
	box-shadow: none;
}
.categories-list .figcaption {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.item.categories-list img {
	width: auto;
	display: block;
	margin-left: auto;
}
.carousel-container .categories-carousel .owl-next,
.carousel-container .categories-carousel .owl-prev {
	top: 7.5vw;
}
.categories-list {
	border: none;	
	background: transparent;
}
.carousel-container {
	max-width: 92.5vw;
	margin: auto;
}
.trends-carousel .owl-prev ,
.trends-carousel .owl-next {
	position: absolute;
	top: 45%;
	color: #de1f65;
	cursor: pointer;
	font-size: 20px;
}
.trends-carousel .owl-prev {
	left: -35px;
}
.trends-carousel .owl-next {
	right: -35px;
}
.trends-carousel .owl-item img {
	border-radius: 0;
	border: none;
}
.trends-list p{
	position: absolute;
	bottom: 5px;
	left: 10px;
	text-align: left;
	color: #fff;
	margin: 0 0 10px 10px;
	white-space: normal;
	z-index: 1;
	text-shadow: rgba(0,0,0,1)0px 0px 25px;
	font-family: 'SF-Pro-Display-Regular';
	font-size: 15px;
	font-weight: normal;
}
.trends-carousel .owl-item {
	box-shadow: none;
	border-radius: 0;
}
.trends-carousel .owl-item.active .img-active img {
	color: #de1f65;
	border-bottom: 3px solid #de1f65;
	padding-bottom: 5px;
}
.owl-prev.disabled, .owl-next.disabled {
    color: #bbb;
}
.compare-page .sticky-header {
    top: 0;
}
.trends-carousel .owl-next, .trends-carousel .owl-prev {
    top: 41%;
}
.trends-carousel .owl-item img {
	padding-bottom: 8px;
}
.trends-list .img-active+p {
    bottom: 5px;
}
}


/* media-query maximun range */
@media (max-width: 1200px) {
.trends-carousel.owl-carousel .owl-item img {
	border: none;
	border-radius: 0;
}
.trends-carousel.owl-carousel .owl-item {
	box-shadow: none;
	border-radius: 0;
}
.trends-carousel.owl-carousel .owl-item .item p {
	position: absolute;
	bottom: 0;
	left: 10px;
	text-align: left;
	color: #fff;
	margin: 0 0 10px 10px;
	white-space: normal;
	z-index: 1;
	text-shadow: rgba(0,0,0,1)0px 0px 25px;
	font-family: 'SF-Pro-Display-Regular';
	font-size: 15px;
	font-weight: normal;
}
.trends-carousel.owl-carousel .owl-item.active .item .img-active img {
	color: #f61eae;
	border-bottom: 4px solid #f61eae;
	padding-bottom: 7px;
}
.trends-carousel.owl-carousel .owl-item img {
	    padding-bottom: 11px;
}
.trends-carousel.owl-carousel .owl-item .item p {
	    margin: 0 0 18px 10px;
}
}

@media (max-width: 1199px) {
.insta-images .col-6 {
    height: 232px;
}	
}

@media (max-width: 1024px) {
.categories-list .figcaption {
	width: 115px;
}
#tab-list.trends-tab-list ul li a img {
    width: 28vw;
}
.trends-tab-list .img-active {
    margin: 10px 12px 0 0;
}
.comp-prd-table tbody tr td, .comp-prd-table tbody tr th {
    padding: 15px 10px 15px;
}
.comp-prd-table tr td, .comp-prd-table tr th {
	padding: 15px 10px;
}
}

@media (max-width: 991px) {
.insta-images .col-6 {
    height: 172px;
}
.trends-tab-list .img-active {
    margin: 10px 10px 0 0;
}
}

@media (max-width: 768px) {
.categories-list .figcaption {
    font-size: 16px;
}
}

@media (max-width: 767px) {
h2.ml-4 {
    margin-left: 15px !important;
}
.brand-carousel.owl-carousel .owl-stage, .categories-carousel.owl-carousel .owl-stage {
    padding-left: 20px !important;
}
.slide .figcaption {
	width: 95%;
    font-size: 17px;
}
.categories-description {
    margin: 0 0 0 10px;
}
.HowToModal video, .carousel-slider {
	width: 100%;
}
.card-image span .fa-play {
	font-size: 15px;
}
.card-image span {
	width: 38px;
	height: 38px;
}
.insta-images .col-12 {
	flex-basis: 100%;
	max-width: 100%;
}
.insta-images .col-6 {
    height: 42vmin;
}
.howtohead {
    text-align: left;
    margin-left: 0;
    font-size: 20px;
}
.popup-backbutton i {
    font-size: 18px;
    color: #DE1F65;
}
.compare-images section {
	flex-basis: 30%;
}
.mob-desc {
	padding-left: 15px !important;
	margin-top: 40px;
}
.howtoapply a img,
.viewer {
	width: 60%;
	margin: 10px auto;
}
.multi-images {
	width: 250px;
	height: initial;
	margin: 0 auto;
	left: 0;
}
.multi-images, .one-image {
	min-height: 25vh;
}
.multi-images img, .one-image img {
	max-height: 100%;
}
.product-detail .people-quotes {
	width: 100% !important;
}
.mob-list {
	padding-left: 18px !important;
}
.comp-prd-table tr th, .comp-prd-table tr td {
	padding: 10px;
}
.comp-prd-table tbody tr th, .comp-prd-table tbody tr td {
    padding: 5px 10px 15px;
}
.desc-head {
	height: auto;
	word-break: break-word;
}
.theme-one .row {
	margin-bottom: 15px !important;
}
.theme-one .row p {
	margin-bottom: 5px;
}
.clr-splash {
	margin-left: -15px;
	margin-right: -15px;
}
.spring-txt {
	margin: 0 15px !important;
}
.trends-tab-list#tab-list .out-wrap #tabs {
    padding-left: 23px;
}
.trends-tab-list .img-active {
    margin: 10px 7px 0 0;
}
#mobFilter.modal.show .modal-dialog {
    transform: translate(0,0);
    top: 10px;
}
.categories-list.categories .figcaption {
    width: 130px;
}
.prd-section .col-md-4.col-12.mt-3 {
    left: 0;
}
}

@media (max-width: 724px) {
.header-text {
    margin-left: 10px;
}
.header-height .only-backbutton i {
	margin-left: 0;
}
header h1 {
	height: 25px;
    padding: 0 15px;
}
.header-text img {
    margin-left: 0;
}
.brands-heading, 
.moveLeft .brands-heading {
    width: calc(100% - 110px);
}
}

@media (max-width: 670px) {
.compare-images {
    width: 100%;
    border-radius: 0;
	flex-direction: column;
	padding: 10px 0;
}
.compare-button {
    flex-direction: row;
	justify-content: center;
    margin-top: 20px;
}
.compare-button button, .compare-button a {
    width: 45%;
    margin-left: 10px;
    margin-right: 10px;
}
.compare-set {
    width: 95%;
    margin: 0 auto;
}
.compare-images article, .img-empty {
    flex-basis: 30%;
}
.category-listing .card-image, .category-listing .card-image img {
	height: auto;
    width: 100%;
}
}

@media (max-width: 576px) {
.compare-images article p {
    font-size: 13px;
}
.m-t {
    margin-top: 0;
}
.item.categories-list img, .categories-list .figcaption {
	min-height: 25px;
    display: inherit;
    margin: 0 auto;
	text-align: center;
	display: flex;
    align-items: center;
    justify-content: center;
}
.skinList li {
    flex-basis: 100%;
	margin-bottom: 0;
}
.footerMenu li a {
    padding: 15px 12px 10px 12px;
}
.search-div {
    width: 90%;
}
.select-link.brandsPlp p {display: none;}
.select-link.brandsPlp .dropdown a.dropdown-toggle {
    width: 100%;
}
.header-height .only-backbutton {
    margin: 17px 0 0 0;
}
.select-link.brandsPlp .dropdown {
    width: 162px;
}
.search-txt {
    margin-bottom: 15px;
}
.item.categories-list img {
    width: 70px;
}
.trends-tab-list#tab-list .out-wrap #tabs {
    padding-left: 10px;
}
#tab-list.trends-tab-list ul li a img {
    width: 150px;
}
.trends-tab-list#tab-list .out-wrap #tabs li a p {
    font-size: 14px;
}
.skinList {
    margin-top: -20px;
}
.categories-list .figcaption {
	min-height: 38px;
	font-size: 14px;
}
.video-wrapper div:last-child {
    display: none;
}
.product-center {
    width: 94%;
}
.checkmark {
    left: 6px;
    top: 15px;
}
.search-top .select-link.brandsPlp {
    margin-top: 0;
}
[alt^="insta_image"] {
    width: 100%;
}
#instagrampopup .modal-body > p {
    height: 60px;
    overflow: auto;
}
.desc-head {
	font-size: 3.1vw;
}
.categories-header, .categories-description {
    position: absolute;
}
.slick-slider {
   min-height: 245px;
}
}

@media (max-width: 375px) {
.categories-list .figcaption {
    width: auto;
}
#tab-list .search-result #tabs {
    width: 100%;
}
#mobFilter .filter-types .btn {
    margin-right: 8px;
    padding: 5px 5px;
}
#mobFilter .filter-types {
    margin-bottom: 0;
}
}

@media (max-width: 320px) {
#tab-list .search-result #tabs {
    width: 290px;
}
.select-link.brandsPlp .dropdown {
    width: 132px;
}
.categories-list .figcaption {
	min-height: 55px;
    word-break: break-all;
}
.select-link .dropdown-menu.show {
    width: 130px;
}
}

/* Device Orientation-queries starts */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
.insta-images .col-md-6:first-child.col-12 {
	height: 344px;
	overflow: hidden;
}
.category-listing .card-image, .category-listing .card-image img {
	height: 123px;
   	width: 210px;
}
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
.insta-images .col-md-6:first-child.col-12 {
	height: 464px;
	overflow: hidden;
}
.category-listing .card-image, .category-listing .card-image img {
	height: 170px;
   	width: 290px;
}
}
/* Device Orientation-queries ends */
@media (max-device-width: 767px) and (orientation: portrait) {
	.slick-slider figure img, .slick-slider .video-react .video-react-poster, .slick-slider .video-react .video-react-video, .slick-slide .video-react.video-react-fluid {
		height: 218px;
		object-fit: cover;
	}
	.slide {
		height: 218px;
		overflow: hidden;
	}
	.slide .figcaption {
		bottom: 0;
	}
	.category-listing .card-image, .category-listing .card-image img {
		height: 92px;
	   	width: 158px;
   	}
}

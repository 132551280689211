/* common style starts */
@font-face {
  font-family: 'FuturaBT-Light';
  src: url('../fonts/FuturaBT-Light.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaBT-Book';
  src: url('../fonts/FuturaBT-Book.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaBT-Medium';
  src: url('../fonts/FuturaBT-Medium.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
	font-family: 'SF-Pro-Display-Light';
	src: url('../fonts/SF-Pro-Display-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'SF-Pro-Display-Regular';
	src: url('../fonts/SF-Pro-Display-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'SF-Pro-Display-Medium';
	src: url('../fonts/SF-Pro-Display-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?kcthp2#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?kcthp2') format('truetype'),
    url('../fonts/icomoon.woff?kcthp2') format('woff'),
    url('../fonts/icomoon.svg?kcthp2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ic {
    font-family: 'icomoon';
    font-style: normal;
    font-size: 25px;
}
.icon-breakline:before {content: "\e900";}
.icon-home:before {content: "\e901";}
.icon-A-Z:before {content: "\e902";}
.icon-categories:before {content: "\e903";}
.icon-videos:before {content: "\e904";}
.icon-star:before {content: "\e905";}
.icon-caret-up:before {content: "\e906";}
.icon-caret-down:before {content: "\e907";}
.icon-caret-left:before {content: "\e908";}
.icon-caret-right:before {content: "\e909";}
.icon-search:before {content: "\e90a";}
/* common style ends */
*:focus {    
    outline: 2px solid #9fc1f8;
    outline: 5px auto #4d90fe;
}
/* splash screen starts */
#splashScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 3;
    background: linear-gradient(to bottom, #3d828e, #bfd7db);
    animation-name: splash;
    animation-duration: 1.2s;
	animation-delay: 1.5s;
}
@keyframes splash {
	0% {opacity : 1;}
	100% {opacity : 0;}
}
#splashScreen img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	padding: 30px;
}
/* splash screen ends */

/* desktop header starts */
.desktop-header {
    background: #fff;
	border-bottom: #e4e4e4 solid 1px;
}
.desktop-header i {
    color: #DE1F65;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.desktop-menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-grow: 1;
    justify-content: center;
	align-items: center;
}
.desktop-menu li {
    list-style: none;
    margin: 0px 12px;
	font-family: 'SF-Pro-Display-Regular';
    font-size: 20px;
}
.desktop-menu li:first-letter {
    text-transform: capitalize;
}
.desktop-menu li a {
    color: #000;
	text-decoration: none;
}
.desktop-menu li a.active {
    border-bottom: #DE1F65 solid 3px;
}
.d-footer {
    width: 100%;
	position: fixed;
    bottom: 0;
    z-index: 1;
    background: #fff;
    padding: 5px;
    text-align: center;
    border-top: #e4e4e4 solid 1px;
	font-family: 'SF-Pro-Display-Regular';
}
.d-footer .h6 {
    font-size: 13.5px;
}
/* desktop header ends */

/* home-page starts */
header {
    width: 100%;
    height: 120px;
    background: #fff;
	z-index: 2;
    position: fixed;
    top: 0;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.20);
    transition: all 0.5s ease;
}
.header-text {
   margin-left: 30px; 
}
.header-text img {
    width: 80px;
    backface-visibility: hidden;
    float: left;
	margin: 20px 6px;
}
.header-text > h1 {
	width: 100%;
	z-index: -1;
	position: absolute;
    top: 70%;
	left: 0;
    transform: translate(0,-70%);
}
.header-height {
    height: 64px;
}
/* header-text animation starts */
.moveCenter .header-text > h1 {
	font-size: 18px;
    left: 50%;
    transform: translate(-50%,-80%);
	text-align: center;
	animation: moveCenter 1s ease;
}
.moveLeft .header-text > h1 {
	font-size: 32px;
	left: 0;
	transform: translate(0,-70%);
	text-align: left;
	animation: moveLeft 1s ease;
}
/* header-text animation ends */

/* header-image animation starts */
.header-height .header-text img {
    transform: rotateX(100deg);
	opacity: 0;
	transition: all 0.7s ease;
}
.moveLeft .header-text img { 
	transform: rotateX(-0deg);
	opacity: 1;
	transition: all 0.7s ease;
}

.moveCenter .brands-heading {
	position: absolute;
	top: 0;
	left: 50%;
    transform: translateX(-50%);
	animation: moveCenter 1s ease;
	margin-top: 20px;
	display: table;
}
.moveLeft .brands-heading {
	position: static;
	top: 5px;
	left: 150px;
    transform: initial;
	animation: moveLeft 1s ease;
    margin-top: 40px;
}
@keyframes moveCenter {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes moveLeft {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.moveCenter .brands-heading h1 {
	text-align: center;
	font-size: 18px;
	font-weight: normal;
    margin: 8px 0 0;
}
.moveLeft .brands-heading h1 {
	text-align: left;
	font-size: 32px;
    margin: 0;
}
.moveCenter .header-text span {
    opacity: 0;
}
.moveLeft .header-text span {
    opacity: 1;
}
/* header-image animation ends */

header h1 {
    font-size: 32px;
    margin: 0;
	font-family: 'SF-Pro-Display-Medium';
    padding: 0 35px;
	text-transform: capitalize;
	line-height: 0.8;
}
.brands-heading {
    float: left;
    margin: 40px 0 0 0;
}
.brands-heading > span {
    display: block;
	line-height: 1.5;
}
.brands-heading h1 {
	padding: 0;
}
.brands-heading h1 b {
    font-weight: normal;
	opacity: 0;
}
.moveCenter .brands-heading h1 b {
    opacity: 1;
}
.moveLeft .brands-heading h1 b {
    opacity: 0;
}
.header-height .brands-heading h1 span {
    opacity: 1;
}
.brands-heading span:last-child {
    text-transform: capitalize;
	font-family: 'SF-Pro-Display-Medium';
    font-weight: 500;
    font-size: 15px;
}
.headingResize {
    width: 100%;
	height: 70px;
    position: fixed;
    top: 0;
    z-index: 1;
    justify-content: center;
}
.headingText {
    justify-content: center;
	animation-name: headingAlign;
    animation-duration: 1s;
    animation-timing-function: ease;
}
@keyframes headingAlign {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
header .ic.icon-search {
    position: absolute;
    right: 20px;
    top: 8px;
    color: #DE1F65;
    cursor: pointer;
    padding: 6px 0 6px 6px;
}
header input, .desktop-header input {
    width: 100%;
	height: 38px;
    top: 0;
    border: none;
    box-shadow: none;
    padding: 6px 30px 6px 30px;
	border-radius: 6px;
	background: #e6e6e8;
}
.search-input {
    width: 85%;
    position: relative;
}
.search-div {
    width: 60%;
    margin: 40px auto auto auto;
	display: flex;
}
.search-set {
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    z-index: 3;
    top: 0;
	left: 0;
    background: #fff;
}
.search-set #searchClose, .search-set #searchCloses {
	color: #DE1F65;
    cursor: pointer;
    margin: 0 0 0 10px;
    line-height: 35px;
	opacity: 1;
}
.search-input .fas-times {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #fff;
    cursor: pointer;
    background: #bbb;
    border-radius: 50px;
    padding: 3px 3px 3px 4px;
    font-style: normal;
    font-weight: bold;
    line-height: 14px;
    font-family: 'FuturaBT-Medium';
    font-size: 15px;
}
.search-input::before {
    content: '\e90a';
    position: absolute;
    top: 5px;
    left: 8px;
    font-size: 18px;
    font-family: 'icomoon';
    color: #8a8a8f;
}
.search-input ul {
    padding-left: 5px;
}
.search-input ul li {
    list-style: none;
	font-family: 'SF-Pro-Display-Light';
	font-size: 15px;
	letter-spacing: -0.1px;
	padding: 5px 0;
}
.search-input ul li .suggestion-content {
    color: #3c3c3c;
}
.search-input ul li > .suggestion-content > .name {
    display: block;
	cursor: pointer;
}
.search-input ul li .name span {
    font-family: 'SF-Pro-Display-Light';
}
.search-input ul li .name .highlight {
    font-family: 'SF-Pro-Display-Medium';
	color: #000;
}
input[type=text]::-ms-clear {
    display: none;
}
.suggest-keyword {
	color: #b1b1b1;
    font-size: 12px;
    font-family: 'SF-Pro-Display-Medium';
    font-weight: normal;
    text-transform: capitalize;
    letter-spacing: 0.7px;
	margin-left: 5px;
	pointer-events: none;
	cursor: default;
}
.react-autosuggest__suggestion.react-autosuggest__suggestion--highlighted {
    background-color: #eee;
    padding: 5px 0;
}
.wrapper {
    background: #f2f2f2;
}
.wrapper h2{
    color: #222;
}
/*.main {
    margin: 140px 0 67px 0;
    animation: slide 1s;
}
@keyframes slide {
    0% {
        opacity: 0;
        transform: translateY(-500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}*/
.main {
    margin: 140px 0 100px 0;
}
.page-left-to-right {
    animation: slideright 1s;
}
.page-right-to-left {
    animation: slideleft 1s;
}
.page-top-to-bottom {
    animation: slidedown 1s;
}
.page-bottom-to-top {
    animation: slideup 1s;
}
@keyframes slidedown {
    0% {
        opacity: 0;
        transform: translateY(-500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideup {
    0% {
        opacity: 0;
        transform: translateY(500px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideleft {
    0% {
        opacity: 0;
        transform: translateX(500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slideright {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.new-arrow {
    visibility: hidden;
}

/* Slider for desktop ends */
.slide-wrapper {
	height: 400px;
	margin-bottom: 33px;
}
.ktrv {
    position: absolute !important;
    top: 215px;
    left: 50%;
    transform: translate(-50%,0);
}
.ktrv.w-carousel {
    top: 58px;
}
.wwkt > div:last-child {
	position: absolute;
}
.wwkt img {
	border: #ddd solid thin;
	box-shadow: rgba(0,0,0,0.2)0px 5px 10px;
}
.b-figure {
    position: relative;
}
.b-figure .figcaption {
    width: 70%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    padding: 10px 10px;
    font-size: 18px;
    background: rgba(255,255,255,0.7);
    color: #333;
    text-transform: uppercase;
    font-family: 'SF-Pro-Display-Medium';
    text-align: center;
}
/* Slider for desktop ends */

/* Slider for mobile starts */
.slider{
	max-width:100%;
	margin:auto;
	text-align: center;
	padding:0;
	color:white;
}
.slide {
    position: relative;
}
.slide .figcaption {
    width: 65%;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%,0);
    padding: 10px 10px;
    font-size: 20px;
    background: rgba(255,255,255,0.7);
    color: #333;
    text-transform: uppercase;
    font-weight: bold;
}
.parent-slide{
	padding:15px;
}
img{
	display: block;
	margin:auto;
}
.slider img {
	width: 100% ;
}
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
	
}
.slick-list.dragging {
	cursor: pointer;
	/*cursor: hand;*/
}
.slick-slider .slick-track, .slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:before, .slick-track:after {
	display: table;
	content: '';
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}
[dir='rtl'] .slick-slide {
	float: right;
}
.slick-slide img {
	width: 100%;
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    background: rgba(0,0,0,0.0);
}
.slick-next {
    right: 20px;
}
.slick-prev {
    left: 20px;
    z-index: 1;
}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before {
    content: '←';
}
.slick-next:before {
    content: '→';
}
.slick-dots {
    display: flex !important;
    justify-content: center;
}
.slick-dots li {
    list-style: none;
    margin: 0 5px;
}
.slick-dots li button {
    width: 30px;
    height: 5px;
    border: none;
	font-size: 0;
	background: #ddd;
	border-radius: 40px;
}
.slick-dots li.slick-active button {
    background: #777;
}
.modalopen .slick-slide img {
    width: 100% !important;
	display: block !important;
	margin: 0 auto !important;
}
/* Slider for mobile ends */

/* brand carousel starts */
.owl-carousel{
    left: -1px;
    display:none;
    width:100%;
    -webkit-tap-highlight-color:transparent;
    position:relative;
	margin: 0;
}
.owl-carousel .owl-stage-outer{
    position:relative;
    overflow:hidden;
	padding-bottom: 10px;
	transform:translate3d(0px,0,0);
    -webkit-transform:translate3d(0px,0,0);
}
.owl-carousel .owl-item{
    position:relative;
    min-height:1px;
    float:left;
	box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
    margin-top: 8px;
    -webkit-backface-visibility:hidden;
    -webkit-tap-highlight-color:transparent;
    -webkit-touch-callout:none;
}
.owl-carousel .owl-dots.disabled,.owl-carousel .owl-nav.disabled{
    display:none; /* next & pre button called as 'nav' */
}
.owl-carousel.owl-loaded{
	display: inline-block;
}
.owl-carousel.owl-loading{
    opacity: 0;
    display: block;
}
.owl-carousel .owl-item img{
    transform-style:preserve-3d;
	border: #ddd solid thin;
	border-radius: 10px;
}
.coming-soon {
    position: relative;
    display: block;
}
.coming-soon .figcaption {
    width: 100%;
    position: absolute;
    bottom: 0;
	color: #fff;
    background: #545454;
    text-align: center;
    border-radius: 0 0 8px 8px;
    padding: 4px 0;
    text-transform: uppercase;
    font-size: 13px;
	font-family: 'FuturaBT-Book';
}
/* brand carousel ends */

/* categories carousel starts */
.categories-list {
    /* overflow: hidden; */
    border: #ddd solid thin;
    box-sizing: border-box;
	background: #fff;
	margin-bottom: 0;
	border-radius: 10px;
}
.categories-list a {
    width: 100%;
    display: table;
	color: #000;
	padding: 9px;
	text-decoration: none;
}
.item.categories-list img {
    width: 80px;
    border: none;
    display: table-cell;
	border-radius: 50px;
    margin-left: 0;
}
.categories-list .figcaption {
	width: 130px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
	line-height: 1.2;
	font-family: 'FuturaBT-Book';
    font-weight: 300;
	letter-spacing: -0.5px;
}
/* categories carousel ends */

.divider {
    font-family: 'icomoon';
    font-style: normal;
	font-size: 20px;
	display: block;
    text-align: center;
    margin: 20px 0;
	color: #3D828E;
}
.divider:after {
    content: '\e900';
}
footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
}
.footerMenu {
    margin: 0 auto;
    padding: 0px 0px 28px 0px;
	background: #fff;
	border-top: #e4e4e4 solid 1px;
    display: flex;
    justify-content: center;
	animation-name: mobileFlyout;
    animation-duration: 0.3s;
	/*animation-delay: 1s;*/
    animation-timing-function: ease-in-out;
}
@keyframes mobileFlyout {
	0% {
		opacity: 0;
		transform: translate(0,10%);
	}
	100% {
		opacity: 1;
		transform: translate(0,0);
	}
}
.footerMenu li {
    list-style: none;
}
.footerMenu li a {
    padding: 2px 25px 5px 25px;
    color: #8F8F8F;
    text-decoration: none;
     display: flex;
    flex-wrap: nowrap;
    flex-direction: column; 
    /*display: block;*/
}
.footerMenu li a.active, .footerMenu.mobileMenu li a.active {
    color: #DE1F65;
}
.footerMenu li a i {
    margin: 0 auto -5px auto;
    /* display: block;
    text-align: center; */
}
.footerMenu li a span {
    font-size: 10px;
    line-height: 1;
    font-family: 'SF-Pro-Display-Regular';
    text-align: center;
    letter-spacing: 0.6px;
}
.footerMenu li a span:first-letter {
    text-transform: capitalize;
}
.menuBars {
	width: 50px;
    height: 50px;
	text-align: center;
    border-radius: 50px;
	float: right;
	background: #DE1F65;
	cursor: pointer;
    margin: 20px 40px 40px 40px;
	display: none;
}
.barAnimation {
	animation-name: test;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}
@keyframes test {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.2);
	}
	60% {
		transform: scale(1);
	}
}
.svg-inline--fa.fa-bars {
    color: #fff;
	font-size: 25px;
    margin: 13px 0 0 0;
}
.footerMenu.mobileMenu {
    display: block;
	background: none;
	margin-right: 20px;
	border: none;
}
.footerMenu.mobileMenu li a i {
    background: #fff;
    border-radius: 50px;
    padding: 7px;
    margin: 0 0 0 10px;
}
.footerMenu.mobileMenu li a {
    padding: 10px 20px;
    color: #555;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
	align-items: center;
}
.footerMenu.mobileMenu li a span {
    font-size: 16px;
    color: #fff;
}
.footerMenu.mobileMenu li:last-child a {
    border-bottom: none;
}
.opacity {
    overflow: hidden;
}
.opacity::before {
	content: '';
	width: 100%;
    height: 100%;
	position: fixed;
	top: 0;
    background: rgba(0,0,0,0.85);
    z-index: 2;
}
/* home-page ends */

/* brand page starts */
.desktop-header a img {
    width: 84px;
}
.brand-img {
    background: #fff;
}
.brand-img img {
    width: 170px;
}
.brand-img a {
	display: block;
}
/* brand page ends */

/* brands-plp page starts */
.plp-content {
    margin: 0 0 40px 0;
	position: relative;
}
.plp-content i {display: none;}
.plp-content a {
    display: block;
}
.plp-content a:hover {
    text-decoration: none;
}
.plp-content p {
    font-size: 15px;
    line-height: 1.3;
    margin: 0 0 5px 0;
	color: #333;
	font-family: 'SF-Pro-Display-Medium';
}
.plp-content img {
    margin: 0 0 10px;
}
.plp-content p:first-letter {
    text-transform: uppercase;
}
.plp-content h2 {
    text-transform: capitalize;
    font-size: 15px;
    color: #333;
    font-weight: bold;
    backface-visibility: hidden;
    font-family: 'SF-Pro-Display-Medium';
}
.checkBox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
	text-align: center;
	cursor: pointer;
	display: none;
}
.checkBox input {
    -webkit-appearance: none;
    appearance: none;
    width: 26px;
    height: 26px;
    margin: -10px 0 0 0;
    position: absolute;
    left: 10px;
    top: 20px; 
    border: 0;
    border-radius: 50px;
}
.checkBox input::-ms-check {
    display: none;
}
.checkmark {
    width: 26px;
    height: 26px;
    border: #DE1F65 solid thin;
    display: inline-block;
    border-radius: 50px;
	margin: -10px 0 0 0;
    position: absolute;
    left: 10px;
    top: 20px;
}
.checkmark::before {
    content: '';
    position: absolute;
    top: 3px;
    right: 8px;
    width: 8px;
    height: 15px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    display: none;
}
.checkBox input:checked + .checkmark {
    background: #DE1F65;
}
.checkBox input:checked + .checkmark::before {
    display: block;
}
.compareDiv {
    margin: 30px 0;
}
.compare {
    width: 135px;
    text-align: center;
    background: #fff;
    text-transform: uppercase;
    font-weight: normal;
    color: #DE1F65;
    cursor: pointer;
    border: #DE1F65 solid thin;
    font-size: 15px;
    font-family: 'FuturaBT-Book';
    line-height: 2.5;
    border-radius: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.backButton, .backButton:hover {
    padding: 0 35px 0 0;
    color: #DE1F65;
    text-transform: capitalize;
    text-decoration: none;
	font-family: 'SF-Pro-Display-Medium';
    font-size: 17px;
	position: absolute;
    top: 22px;
}
.backButton i {
    font-size: 18px;
    margin: 0 4px 0 0;
}
.h-two {
    text-transform: capitalize;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    backface-visibility: hidden;
    font-family: 'SF-Pro-Display-Medium';
}
.compare-images {
    width: 670px;
    position: fixed;
    bottom: 68px;
    left: 50%;
    transform: translate(-50%, 0%);
	display: flex;
    background: rgba(0,0,0,0.8);
    padding: 15px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
	z-index: 3;
    animation-name: compares;
    animation-fill-mode: forwards;
    animation-duration: 1s;
}
.compare-images.delay{
    animation-delay: 1s;
}
@keyframes compares {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.compare-set {
    display: flex;
	-ms-flex: 1;
}
.compare-images article, .img-empty {
    flex-basis: 27%;
	position: relative;
	margin: 0 10px 0 3px;
}
.compare-images article i {
    width: 22px;
    height: 22px;
    font-style: normal;
    color: #DE1F65;
    border: #DE1F65 solid 1px;
    border-radius: 50px;
    display: block;
    text-align: center;
    background: #fff;
    font-weight: bold;
    position: absolute;
    right: -5px;
    top: -3px;
    cursor: pointer;
    line-height: 17px;
}
.compare-images article p {
	min-height: 64px;
    color: #fff;
    line-height: 1.1;
    margin: 10px 0 0 0;
    text-transform: capitalize;
}
.compare-button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.compare-button button, .compare-button a {
    width: 100%;
    text-transform: uppercase;
    font-size: 15px;
	text-align: center;
    border-radius: 50px;
    border: 0;
    padding: 7px 20px;
    margin: 5px 0;
	cursor: pointer;
    color: #fff;
    white-space: pre;
}
.compare-button .btn-disabled {
    background: #979797;
}
#elementAdded article label {
    display: none !important;
}
.only-backbutton {
    display: none;
}
.header-height .only-backbutton {
    display: inline-block;
    margin: 17px 0 0 10px;
	text-decoration: none;
	position: absolute;
    left: 10px;
}
.header-height .only-backbutton i {
    font-size: 21px;
    padding: 10px 10px 10px 0;
    margin: 13px 0 0 13px;
    color: #DE1F65;
}
.pdp-backbutton, .pdp-backbutton:hover {
    float: left;
    margin: 40px 0 0 0;
	text-decoration: none;
}
.pdp-backbutton i {
	color: #DE1F65;
	font-size: 23px;
}
.header-height .pdp-backbutton {
    opacity: 0;
}
.cancel-confirmation {
	width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	background: #fff;
	box-shadow: rgba(0,0,0,0.5)0px 0px 15px;
	border-radius: 5px;
}
.cancel-confirmation span {
    display: block;
    text-align: center;
    border-bottom: #ddd solid thin;
    text-transform: uppercase;
    padding: 7px 0;
    font-family: 'SF-Pro-Display-Medium';
    letter-spacing: 1.1px;
}
.cancel-confirmation p {
    padding: 15px;
	margin: 0;
	text-align: center;
}
.cancel-confirmation + button svg {
    display: none;
}
.btn-group {
    width: 100%;
    justify-content: center;
    margin: 0 0 15px;
}
.btn-group button {
    width: 100px;
    margin: 0 10px;
    border-radius: 50px;
    border: #DE1F65 solid thin;
    background: none;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px;
	cursor: pointer;
}
.no-products {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 16px;
}
/* brands-plp page ends */

/* brands-detail page starts */
/* Tab starts */
#tab-list, #tab-list * {
    
}
#tab-list .out-wrap {
    position: relative;
}
#tab-list .out-wrap #tabs {
    border-bottom: 0;
    margin: 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
#tab-list .out-wrap #tabs::-webkit-scrollbar {
    display: none;
}
#tab-list .out-wrap #tabs li {
    width: auto;
	font-size: 17px;
    font-family: 'FuturaBT-Book';
    white-space: nowrap;
	letter-spacing: -0.5px;
    flex: 1;
}
#tab-list .out-wrap #tabs li a {
    border: 0;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    color: #979797;
    text-transform: uppercase;
    position: relative;
    display: block;
    padding: 10px;
	text-align: center;
}
#tab-list .out-wrap #tabs li a:hover {
    color: #DE1F65;
    text-decoration: none;
}
#tab-list .out-wrap #tabs li.active a {
    color: #DE1F65;
    border-bottom: 1px solid #DE1F65;
}
#tab-list .out-wrap .tab-content {
    overflow: hidden !important;
    margin-top: 20px;
}
/* Tab ends */
.accHeading {
    cursor: pointer;
}
.header-logo img {
    width: 20%;
	margin: 0 0 0 20px;
}
.categories-wrapper {
    background: #fff;
    padding: 20px 20px 5px 20px;
	margin: 0 0 10px 0;
	position: relative;
}
.categories-wrapper a {
    color: #333;
	text-decoration: none;
}
.categories-content {
    display: flex;
    flex-direction: column;
    position: relative;
}
.categories-content h3 {
    font-family: 'FuturaBT-Book';
    font-size: 24px;
}
.categories-content p {
    width: 90%;
}
.categories-content .svg-inline--fa {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
    font-size: 25px;
	color: #DE1F65;
}
a.categories-acc {
	text-decoration: none;
	display: block;
    margin: 0 0 20px;
}
.categories-acc h3 {
    text-align: center;
    text-transform: capitalize;
    color: #000;
    font-family: 'FuturaBT-Medium';
	margin-top: 5px;
	font-size: 17px;
}
.categories-content h3::after {
    content: '\e907';
    font-family: 'icomoon';
    z-index: 1;
    color: #DE1F65;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 0;
}
.expand-category.categories-wrapper .categories-content h3::after {
    content: '\e906';
}
.insta-images {
    padding: 0 15px;
}
.insta-images [class*= 'col-'] {
    padding: 1px;
}
.insta-images .col-6 {
    height: 277px;
	overflow: hidden;
}
.accContent {
   display: none;
}
.hero-text {
    width: 100%;
    font-size: 17px;
    text-transform: uppercase;
    font-family: 'FuturaBT-Medium';
    font-weight: 500;
    margin: 0 0 15px 0;
    padding: 0 15px;
	color: #282828;
}
.power-container {
	width: 100%;
	margin: 0 0 12px 0;
	padding: 0 15px;
}
.power-text {
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'FuturaBT-Medium';
    color: #282828;
}
.skinList {
    padding: 0;
	display: flex;
    flex-wrap: wrap;
}
.skinList li {
	flex-basis: 50%;
    list-style: none;
    font-size: 20px;
    margin: 20px 0 8px 0;
    text-transform: capitalize;
    font-family: 'SF-Pro-Display-Light';
    font-weight: 300;
}
.skinList li a::after {
    content: '\e909';
    font-family: 'icomoon';
    font-size: 15px;
    margin: 0 0 0 5px;
    color: #DE1F65;
}
#extracts ul {
   padding-left: 18px;
}
.IE-fix img {
    height: 400px;
    object-fit: cover;
}
/* brands-detail page ends */

/* categories page starts */
.categories-header, .categories-description {
	width: 91%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    height: calc(100vh - 240px);
}
.categories-description {
    width: 100%;
}
.d-tab {
    visibility: hidden;
}
.categories-list img {
    border-radius: 100px;
}
.categories-header {
    background: #fff;
    padding: 10px;
    box-shadow: rgba(0,0,0,0.060)0px 0px 10px;
}
.categories-list.categories {
    border-radius: 0;
    border: none;
    margin: 0 0 10px;
    position: relative;
    overflow: initial;
	box-shadow: 0 0.125rem 1.25rem rgba(0, 0, 0, 0.075);
}
.categories-list.categories a {
    padding: 0;
}
.categories-list.categories img {
    width: 90px;
	border-radius: initial;
	margin: 0;
	backface-visibility: hidden;
}
.categories-list.categories .figcaption {
    width: 150px;
	color: #fff;
	padding-right: 10px;
	text-align: right;
}
.categories-list.categories.active {
    border: #DE1F65 solid 3px;
}
.cat-list {
    padding: 0px 0px 50px 0px;
    margin: 0;
}
.cat-list li {
    list-style: none;
    margin: 35px 0;
	font-size: 18px;
    font-family: 'SF-Pro-Display-Light';
}
.cat-list li a {
    color: #333;
    text-transform: capitalize;
	text-decoration: none;
}
.cat-list li a:hover {
	color: #DE1F65;
}
.cat-list li a i {
    font-size: 12px;
    margin: 0 0 0 6px;
	color: #DE1F65;
}
/* categories-accordion starts */
.d-categories {
    background: #fff;
    position: relative;
    border-radius: 8px;
	margin: 0 0 10px;
}
.d-caption {
    height: 100%;
    font-size: 18px;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    align-items: center;
	text-align: right;
	color: #fff;
}
.mobile-cat-expand.d-categories .d-Heading::after {
    content: '\e906';
}
.d-img {
    padding: 10px;
}
.d-list {
    padding: 1px 15px;
    margin: 0;
}
.d-list li {
    list-style: none;
    margin: 20px 0;
}
.d-list li a {
    color: #333;
    text-transform: capitalize;
    text-decoration: none;
    display: block;
	font-size: 16px;
}
.d-list li a i {
    font-size: 13px;
    margin: 0 0 0 6px;
    color: #DE1F65;
    float: right;
}
.d-Heading {
    margin: 0;
	border-radius: 8px;
    position: relative;
}
.d-Heading::after {
    content: '\e907';
    font-family: 'icomoon';
    z-index: 1;
    color: #fff;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 40%;
}
.mobile-cat-expand .d-Heading {
    border-radius: 8px 8px 0 0;
}
.d-categories .col-6 {
    padding-left: 0;
}
/* categories-accordion ends */
/* categories page ends */

/* page transition code */
.page-wrapper {
    margin-top: 100%;
    transition-property: all;
    overflow: hidden;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.page-wrapper.page-trans {
    margin-top: 0;
    overflow: auto;
}
.loading-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0px);
    -webkit-transform: translate(-50%, 0px);
    height: 100%;
}
/* page transition code */

/* How-to starts */
.category-listing .card, .category-listing .card-img-top {
    border-radius: 0;    
}
.category-listing .card {
    margin-bottom: 20px;
    border: none;
    background: transparent;
	display: inline-block;
}
.category-listing .card-title {
    font-size: 15px;
    font-family: 'SF-Pro-Display-Medium';
}
.category-listing .card-text {
    text-transform: uppercase;
    font-size: 17px;
    line-height: 1.2;
    font-family: 'FuturaBT-Book';
    color: #282828;
}
.category-listing .card-body {
   padding: 10px 0;
}
.card-image, .card-image img {
    position: relative;
}
.category-listing .card-image, .category-listing .card-image img {
    height: 150px;
    width: 255px;
    object-fit: cover;
}
.card-image span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50px;
    height: 50px;
    border: 3px solid #fff;
    border-radius: 30px;
    cursor: pointer;
	display: flex;
    justify-content: center;
    align-items: center;
}
.card-image span .fa-play, .card-image span svg {
    color: #fff;
    font-size: 16px;
    margin-left: 3px;
}
.video-wrapper {
	position: relative;
}
.video-wrapper div#contentHide {
    position: absolute;
    bottom: 50px;
    left: 20px;
    color: #fff;
}
.video-wrapper div#contentHide p {
    font-size: 21px;
}
video{
    width: 100%;
    margin: 0;
}
#playicon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
	transform: translate(-50%,-50%);
    border: #fff solid 3px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#playicon .fa.fa-play {
    border: #fff solid 12px;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    margin-left: 15px;
}
#playicon svg.fa-play {
    font-size: 28px;
    color: #fff;
    padding: 0 0 0 5px;
}
.search-top .select-link.brandsPlp {
    margin-top: -20px;
}
.select-link.brandsPlp {
	height: 39px;
	display: flex;
	margin: 0 0 0 auto;
}
.select-link.brandsPlp p {
    padding: 10px 0 0 0;
    margin: 0;
    font-family: 'SF-Pro-Display-Regular';
    text-transform: uppercase;
    font-size: 14px;
	color: #666;
	letter-spacing: 0.2px;
}
.select-link {
    margin: 25px 0 30px 30px;
}
.select-link .dropdown {
    margin-left: 16px;
    border: 1px solid #979797;
    border-radius: 7px;
}
.select-link .dropdown a.dropdown-toggle {
    color: #666;    
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    line-height: 1;
    font-size: 15px;
    background: #fff;
	padding: 9px 10px;
	border-radius: 7px;
    font-family: 'SF-Pro-Display-Regular';
}
.select-link .dropdown a .ic {
    color: #DE1F65;
	font-size: 17px;
}
.select-link .dropdown a .svg-inline--fa.fa-filter {
    font-size: 25px;
}
.select-link .dropdown-item {
	color: #666;
    padding: 5px 17px;
	font-size: 14px;
    font-family: 'SF-Pro-Display-Medium';
	white-space: normal;
}
.select-link .dropdown-item.active {
	font-weight: bold;
    backface-visibility: hidden;
    background: transparent;
    color: #000;
}
.select-link .dropdown-toggle {
    width: 170px;
}
.select-link .dropdown-menu.show {
    box-shadow: 1px -3px 6px 1px rgba(0,0,0,0.2);    
    border: none;
    border-radius: 8px;
    width: 172px;    
	transform: translateX(-50%) !important;
	z-index: 1;
}
.select-link .dropdown-menu[x-placement="bottom-start"] {
	top: 54px !important;
	left: 50% !important;    
}
.select-link .dropdown-menu[x-placement="top-start"] {
	top: auto !important;
    left: 50% !important;
    bottom: 54px !important;
	box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
}
.select-link .dropdown-toggle::after, .select-link .dropdown-menu[x-placement="top-start"]::before {
    display: none;
}
.select-link .ic {
    font-size: 20px;
}
.select-link .dropdown-menu[x-placement="bottom-start"]::before {
    content: '';
    border: #fff solid 12px;
    border-color: transparent transparent #fff #fff;
    position: absolute;
    top: -10px;
    left: 30px !important;
    transform: rotate(-225deg);
    box-shadow: -3px 3px 5px 0px rgba(0,0,0,0.2);
}
.select-link .dropdown-menu[x-placement="top-start"]::after {    
    content: '';
    border: #fff solid 12px;
    border-color: transparent transparent #fff #fff;
    position: absolute;
    bottom: -10px;
    left: 30px !important;
    transform: rotate(-45deg);
    box-shadow: -3px 3px 5px 0px rgba(0,0,0,0.2);
}
.select-link .dropdown-toggle::after {
    display: none;
}
.select-link .dropdown-menu::before {
    content: '';
    border: #fff solid 12px;
    border-color: transparent transparent #fff #fff;
    position: absolute;
    top: -10px;
    left: 30px !important;
    transform: rotate(-225deg);
    box-shadow: -3px 3px 5px -2px rgba(0,0,0,0.2);
}
.select-link .fa-filter {
    border-left: 1px solid #888;
    padding-left: 10px;
    font-size: 25px;
}
.select-link .dropdown a.dropdown-toggle span {
    white-space: nowrap;
    width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
	text-align: left;
}

/* HowToModal begins here */
.HowToModal .modal-body {
    padding: 20px;
}
.HowToModal .modal-content {
    border: none;
    border-radius: 0;
}
.HowToModal img {
    width: 100%;
    margin: 0;
}
a.howtolink {
    padding: 14px;
    color: #DE1F65;
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'SF-Pro-Display-Medium';
}
.howtohead {
    margin-bottom: 10px;
    font-size: 24px;
    color: #282828;
}
.howtodesc {
    margin:20px 0 30px;
}
.modal-links a {
    color: #DE1F65;
    text-transform: uppercase;
	margin: 10px 0;
    font-size: 15px;
	font-family: 'SF-Pro-Display-Medium';
}
.modal-links .ic {
    font-size: 15px;
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
}
button.close {
    position: absolute;
    right: -7px;
    top: -7px;
    background-color: #B2B8BE;
    font-size: 17px;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50px;
    z-index: 1;
    opacity: 1;
}
button.close span {
    font-size: 18px;
    line-height: 16px;
}
button.close:hover {
    color: #fff !important;
}

/* image popup starts here */
.carousel-inner img {
    width: 100%;
    height: 100%;
}
.modalslider {
    cursor: pointer;
}
.modalslider .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    margin-bottom: 5px;
}
.modalslider .slick-dots li button {
    width: 10px;
    height: 12px;
    border-radius: 10px;
    padding: 7px;
}
.slick-slide:focus {
    outline-color: transparent;
}

/* image carousel popup starts here*/
.carouselImage .modal-dialog {
    max-width: 100%;
    margin: auto;
}
.carouselImage .modal-content,.carouselImage .modal-dialog,
.carouselImage .imageslider, .carouselImage .modal-body {
    height: 100%;
}
.carouselImage .modal-body {
    padding: 0;
}
.carouselImage .slick-dots li {
	width: 110px;
    margin: 0 10px;
}
.carouselImage .slick-dots li.slick-active button {
    background: transparent;
}
.carouselImage .slick-dots li button {
    max-width: 200px;
    width: 100%;
    height: auto;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
}
.carouselImage .slick-dots .slick-active {
    border-bottom: 5px solid #DE1F65;
}
.carouselImage .slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    margin-bottom: 0;
}
.carouselImage .slick-list {
    top: 50%;
    transform: translateY(-60%) !important;
    -webkit-transform: translateY(-60%) !important;
    -moz-transform: translateY(-60%) !important;
    -ms-transform: translateY(-60%) !important;
    -o-transform: translateY(-60%) !important;
}
.carouselImage {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
}
.carouselImage .carousel-slider, .carouselImage  .modalslider, .carouselImage  .slick-slider {
    height: 100%;
}

/* Filter popup css */
#mobFilter .howtohead {
    font-family: 'FuturaBT-Medium';
	font-size: 25px;
}
#mobFilter .modal-body {
    padding: 20px;
}
#mobFilter .filterhead {
    margin: 15px 0;
    font-family: 'FuturaBT-Medium';
    text-transform: uppercase;
    font-size: 18px;
}
#mobFilter .filter-types {
    margin-bottom: 20px;
    width: 100%;
    display: inline-block;
    clear: both;
}
#mobFilter .filter-types .btn {
	color : #222;
	background : transparent;
	border : #888 solid 1px;
	border-radius : 0;
	margin-right : 10px;
	margin-bottom : 10px;
	min-width : 76px;
	font-size : 16px;
	float : left;
}
#mobFilter .filter-types .btn:focus, #mobFilter .filter-types .btn.checked {
	color : #fff;
	background : #DE1F65;
	border : rgb(222, 31, 101) solid 1px;
	box-shadow : none;
}
#mobFilter .apply-btn {
    color: #fff;
    background: #DE1F65;
    width: 100%;
    border-radius: 25px;
    margin-top: 10px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 18px;
}
/* .modal .modal-dialog.close-m {
    transform: translate(0,-50%);
} */
.modal-dialog.close-m .modal-content button {
    top: 10px;
    right: 10px;
}
/* How-to ends */

/* Product detail starts */
.product-detail img {
    width: 100%;
}
.product-detail .compare-btn {
    background: #fff;
    border: 1px solid #f71590;
    border-radius: 20px;
    margin: 25px 0 20px;
	padding: 5px 30px;
    color: #f71590;
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'FuturaBT-Book';
}
.product-detail .prd-sub-head {
    margin-bottom: 17px;
    font-family: 'FuturaBT-Book';
}
.product-detail .desc-list {
    padding-left: 10px;
}
.product-detail .hero-label {
    width: 120px;
    background: #515151;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px;
    color: #fff;
    font-family: 'FuturaBT-Medium';
    margin-bottom: 20px;
}
.product-detail .prd-quotes {
    font-family: 'FuturaBT-Book';
    font-size: 17px;
}
.product-detail small {
    font-size: 13px;
    font-weight: bolder;
    font-family: 'SF-Pro-Display-Medium';
    margin: 0 0 15px;
    display: inline-block;
}
.product-detail .people-quotes p, .product-detail .people-quotes ul {
    font-family: 'FuturaBT-Book';
    font-size: 15px;
}
.product-detail .prd-link, .product-detail .prd-link:focus, .mob-list a {
    color: #f71590; 
    text-decoration: underline;
}
.mob-desc p > small {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 13px;
}
.mob-list li, .desc-list li {
    font-family: 'SF-Pro-Display-Light';
    font-size: 15px;
    margin: 0 0 3px 0;
}
.mob-list ul > ul li {
    list-style: none;
}
.howtoapply .card-image span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.watch-link, .watch-link:focus {
    color: #f71590; 
    text-transform: uppercase;
	font-size: 15px;
    font-family: 'FuturaBT-Book';
}
.prd-section .col-md-4.col-12.mt-3 {
    position: relative;
    left: 15px;
}
.multi-images img {
	box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}
.multi-images, .one-image {
	width: 70%;
	min-height: 170px;
    position: relative;
	left: 15px;
}
.one-image { 
    left: 0;
}
.multi-images img, .one-image img {
	position: absolute;
}
.multi-images img:first-child {
    top: -15px;
    right: -15px;
}
.multi-images img:last-child {
    top: 15px;
    left: -15px;
}
.one-image img:first-child {
    top: -15px;
}
.multi-images.one-image.single-image {
    left: -13px;
}
#HowToApplyVideo .modal-body, 
#HowToApplyImage .modal-body, #IngredientsPopup .modal-body {
    padding: 20px;
}
.modalslider .slick-dots {
    padding: 0;
}
#instagrampopup .modal-body {
    padding: 20px;
	box-shadow: rgba(0,0,0,0.4)0px 0px 20px;
}
.carouselImage .slick-dots {
    padding-left: 0;
}
.best-seller-icon {
	width: 50px !important;
    position: absolute;
    top: 10px;
    right: 10px;
	pointer-events: none;
}
.row-m {
    display: flex;
    flex-wrap: wrap;
}
.product-center {
    width: 85%;
    margin: 0 auto;
}
.how-pdp strong {
    font-family: 'SF-Pro-Display-Medium';
    font-weight: 300;
    font-size: 15px;
}
#IngredientsPopup .loading > img {
    width: auto;
    height: auto;
    margin: 0 auto;
}
.modalopen {overflow:hidden;}
/* Product detail ends */

/* search-result starts */
#tab-list .search-result #tabs {
    width: 350px;
    margin: 0 auto;
	border-radius: 6px;
	border: #DE1F65 solid thin;
}
#tab-list .search-result #tabs li a {
    color: #DE1F65;
}
#tab-list .search-result #tabs li.active a {
    background: #DE1F65;
	color: #fff;
	border: none;
}
#tab-list .search-result #tabs {
    margin: 40px auto;
}
.search-txt {
	width: 100%;
    font-size: 20px;
	margin-bottom: 0;
}
.result-txt{
    font-family: 'SF-Pro-Display-Medium';
	font-size: 20px;
	line-height: 1.9;
}
.no-search {
    position: relative;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.8);
    z-index: 3;
}
/* search-result ends */

/* compare-page starts */
.comp-prd-table tr th, .comp-prd-table tr td {
    width: 25%;
    padding: 20px;
}
.comp-prd-table tr th {
    font-weight: normal;
}
.compare-prd {
    margin-bottom: 20px;
}
.compare-prd > a {
    text-decoration: none;
    color: #000;
	text-align: left;
}
.desc-head {
    font-family: 'FuturaBT-Medium';
    font-size: 16px;
    margin: 0;
	text-align: left;
}
.tick-icon {
    display: block;
    text-align: center;
    margin: 15px 0;
}
.comp-prd-table tbody tr th, .comp-prd-table tbody tr td {
    padding: 5px 20px 15px;
}
.comp-prd-table thead th, .comp-prd-table tbody tr:first-child th, .comp-prd-table tbody tr:first-child td {
    border-top: 1px solid transparent;
}
.comp-prd-table th:first-child {
    background: #ddd;
}
.table th, .table td {
    border-top: 1px solid #ccc;
}
.table tbody tr td .desc-head {
	height: 17px;
}
.attribute-section{
    display: contents;
}
.sticky-header {
    position: sticky;
    position: -webkit-sticky;
    top: 65px;   
    background: #f2f2f2;
}
.table thead th {
    border-bottom: 1px solid #ccc;
}
.sticky-header table > thead > tr > th {
    vertical-align: top;
}
.table tbody tr td {
    vertical-align: middle;
}
.sticky-header .desc-head {
    text-align: center;
}
/* compare-page ends */

/* trends starts */
.trend-bg {
    background: #fff;
}
.trend-bg ul {
    padding-left: 16px;
}
.trends-tab-list#tab-list .out-wrap #tabs {
    border-bottom: none;
    padding-left: 30px;
}
.trends-tab-list#tab-list .out-wrap #tabs li.active a {
    color: transparent;
    border-bottom: transparent;
}
.trends-tab-list#tab-list .out-wrap #tabs li.active .img-active img, 
.trends-tab-list#tab-list .out-wrap #tabs li a.active .img-active img {
    color: #f61eae;
    border-bottom: 4px solid #f61eae;
	padding-bottom: 7px;
}
.trends-tab-list .img-active {
    margin: 10px 20px 0 0;
	position: relative;
}
.trends-tab-list#tab-list .out-wrap #tabs li a {
    padding: 0;
	position: relative;
}
.trends-tab-list#tab-list .out-wrap #tabs li a p {
    position: absolute;
    bottom: 0;
    left: 10px;
    text-align: left;
    color: #fff;
	margin: 0 0 10px 10px;
	white-space: normal;
	z-index: 1;
	text-shadow: rgba(0,0,0,1)0px 0px 25px;
	font-family: 'SF-Pro-Display-Regular';
    font-size: 15px;
    font-weight: normal;
}
.trends-tab-list#tab-list .out-wrap #tabs li a.active p {
    bottom: 11px;
}
.trend-text-strong {
    height: 20px;
    display: block;
	text-transform: uppercase;
	font-family: 'SF-Pro-Display-Medium';
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.1px;
}
.trends-list ul {
    padding: 0 0 0 20px;
    margin: 0;
}
.trends-list ul li {
    margin: 0 0 20px;
    line-height: 1.4;
}
.trend-head {
    font-family: 'FuturaBT-Light';
    font-size: 36px;
    line-height: 34px;
	color: #282828;
}
.theme-head {
    font-family: 'FuturaBT-Book';
	color: #282828;
}
.desc-list {
    margin-bottom: 1rem;
}
.trends-list .img-active + p {
	bottom: 13px;
}
/* trends ends */

/* 404 starts */
.page404 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.page404 img{
	animation-name: page404;
    animation-delay: 1s;
	animation-duration: 1s;
	animation-timing-function: ease;
    animation-fill-mode: forwards;
	opacity: 0;
}
@keyframes page404 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
/* 404 ends */

.skipnav {
    text-align: left
}
.skipnav a {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    background: #de1f65;
    color: #fff;
    padding: 5px
}
.skipnav a:active,
.skipnav a:focus {
    position: static;
    left: 0;
    width: auto;
    height: auto;
    overflow: visible;
    text-decoration: underline
}

@media (min-width: 1025px) {
    .carouselImage .slick-list {
        height: 80%;
    }

    .carouselImage .slick-list .slick-track {
        height: 100%;
    }

    .carouselImage .slick-list .slick-track .slick-slide > div {
        height: 100%;
        text-align: center;
    }

    .carouselImage .slick-list .slick-track .slick-slide img {
        width: auto !important;
        height: 100%;
    }
}
.parent-loading-publish-status {
    height: 100vh;
    position: absolute;
    z-index: 9999;
    width: 100%;
    background: #fff;
    margin: 0 auto;
    left: 0;
    top: 0;
}

.error-content-center {
    margin: 0 auto;
    text-align: center;
}

.d-footer-options {
    margin-left: 14px;
}

.d-footer-content {
     margin-left: -10px;
     margin-right: -10px;
     padding-top: 5px;
     margin-bottom: 0px;
}

.footer-link {
    margin-right: 25px;
    font-weight: bold;
}

.footer-border {
    border-top: #0000000f solid 1px;
}

.footer-page-header {
    padding-top: 20px;
    padding-bottom: 15px;
}

.link-hover {
    color: #000000c2 !important;
}

.main-footer {
    margin-bottom: 20px;
}

.d-footer-ios {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
    background: #fff;
    text-align: center;
    border-top: #e4e4e4 solid 1px;
    font-family: 'SF-Pro-Display-Regular';
}

.d-footer-opt {
    margin-left: 20px;
}

.d-footer-copyright {
     margin-left: -10px;
     margin-right: -10px;
}

.h5 {
    font-size: 11.5px;
}
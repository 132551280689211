#LoginForm {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to top, rgba(222, 31, 101, 0.81) 0%, rgba(219,54,164,0.34) 100%);
}
.panel h2 { color: #de1f65; font-size:18px;;}
.panel h2, label {
    font-weight: bold;
}
.login-form .form-control {
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    font-size: 16px;
    height: 40px;
}
.main-div {
    background: rgb(255,255,255,0.5) none repeat scroll 0 0;
    border-radius: 2px;
    margin: auto;
    max-width: 400px;
    padding: 40px;
    border: 1px solid #de1f65;
}
.login-form  .btn.btn-primary {
    background: #de1f65;
    border-color: #de1f65;
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    height: 40px;
}
